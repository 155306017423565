import React from "react";
import logo from "../assests/pngs/logo.png";
import meta from "../assests/svgs/metamask-icon.svg";
import SideBar from "./SideBar";
import { IoCopyOutline } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useConnectWallet } from "@web3-onboard/react";
import { useBlockchainDeployToken } from "../service/DeployTokenService";
import { useMetaMask } from "../ContextApi/MetaMaskProvider";
import { AiOutlineDisconnect } from "react-icons/ai";

const Navbar = () => {
  const {
    address,
    connectWallet,
    disconnectWallet,
    copyText,
    handleGetAllDeployedTokens,
    connecting,
  } = useMetaMask();

  const notify = () => {
    toast("Address Copied", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progressStyle: { background: "#0061f2" },
      theme: "info",
    });
  };

  const handleCopyText = () => {
    copyText();
    notify();
  };

  const handleConnect = async () => {
    try {
      await connectWallet();
    } catch (error) {
      console.error("Connection error:", error);
      // Handle error as needed
    }
  };

  return (
    <div className="flex justify-between bg-[#181919] w-full text-white items-center h-[60px] font-poppins z-50 sticky top-0">
      <Link to={"/"} className="ml-2 md:ml-12 cursor-pointer">
        <img
          src={logo}
          alt="logo"
          className="w-40 h-[40px] sm:w-48 sm:h-[47px]"
        />
      </Link>
      <div className="flex items-center">
        {/* <div>
          <Link
            to="/documentation"
            className="text-[10px] md:text-[12px] lg:text-[14px] hover:underline underline-offset-2 pr-3 sm:pr-4"
          >
            Documentation
          </Link>
        </div> */}
        <div>
          <Link
            to="/learn"
            className="text-[10px] md:text-[12px] lg:text-[14px] hover:underline underline-offset-2 pr-3 sm:pr-0"
          >
            Knowledge Base
          </Link>
        </div>
        <div className="mx-[1.5rem]  hidden sm:block">
          {address ? (
            <div className="flex ">
              <div
                onClick={handleCopyText}
                className="text-[12px] p-2 mr-4 cursor-pointer flex items-center font-medium rounded-lg hover:bg-[#0668AF] ease-in-out duration-200 bg-[#0F2434]"
              >
                {address.substring(0, 9)}...
                <span className="hidden sm:block">
                  {address.substring(34, 43)}
                </span>
                <span className="pl-2">
                  <IoCopyOutline className="hover:cursor-pointer" />
                </span>
              </div>
              <div>
                <button
                  onClick={disconnectWallet}
                  className="flex items-center sm:text-[12px] lg:text-[12px] rounded-lg p-2 cursor-pointer hover:bg-[#0668AF] ease-in-out duration-200 bg-[#0F2434] "
                >
                  <span className="pr-2">
                    <AiOutlineDisconnect />
                  </span>
                  Disconnect
                </button>
              </div>
            </div>
          ) : (
            <div className="hidden sm:block">
              <button
                className="border-transparent rounded px-2 py-2 hover:bg-[#0668AF] bg-[#0F2434] flex items-center "
                onClick={handleConnect}
              >
                <img
                  src={meta}
                  style={{ width: "20px" }}
                  className="hidden sm:block"
                  alt="meta"
                />
                <span className="ml-2 text-[12px]">Add Idea Network</span>
              </button>
            </div>
          )}
          <ToastContainer toastStyle={{ backgroundColor: "black" }} />
        </div>
        <div className="mr-3 md:mr-16 leading-3">{<SideBar />}</div>
      </div>
    </div>
  );
};

export default Navbar;
