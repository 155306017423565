import React from "react";
import metaimg from "../assests/pngs/metaimg.jpg";
import meta1 from "../assests/pngs/meta1.png";
import meta2 from "../assests/pngs/meta2.png";
import meta3 from "../assests/pngs/meta3.png";
import meta4 from "../assests/pngs/meta4.png";
import meta5 from "../assests/pngs/meta5.png";
import meta6 from "../assests/pngs/meta6.png";
import meta7 from "../assests/pngs/meta7.png";
import meta8 from "../assests/pngs/meta8.png";
import meta9 from "../assests/pngs/meta9.png";
import meta10 from "../assests/pngs/meta10.png";
import meta11 from "../assests/pngs/meta11.png";
import meta12 from "../assests/pngs/meta12.png";
import meta13 from "../assests/pngs/meta13.png";
import meta14 from "../assests/pngs/meta14.png";
import meta15 from "../assests/pngs/meta15.png";
import meta16 from "../assests/pngs/meta16.png";
import { Stepper } from "react-form-stepper";
import StepperKno from "../components/Stepperkno";

const Learn = () => {
  return (
    // <div className="flex">
    //   <div></div>
    <div className="text-[#FFFFFF] max-w-[1000px] md:ml-[30%] px-4 pt-4 md:pb-[24rem]">
      <StepperKno />
      <div className="mt-14">
        <img
          src={metaimg}
          loading="lazy"
          alt="Idea"
          className="w-full max-w-[1176px] h-auto sm:h-[400px] md:h-[500px] lg:h-[647px] mx-auto"
        />
      </div>
      <div
        id="Connecting MetaMask to IDEA 3.0"
        style={{ scrollMarginTop: "80px" }}
        className="my-12"
      >
        <h1 className="text-center sm:!text-left text-[18px] sm:text-[26px] md:text-[36px] lg:text-[48px]">
          Connecting MetaMask to IDEA 3.0
        </h1>
        <p className="text-center sm:!text-left text-[12px] sm:text-[14px] md:text-[16px] leading-[35px] font-poppins font-normal my-4">
          MetaMask is a widely used cryptocurrency wallet that enables access to
          blockchain applications and decentralized finance (DeFi). Originally
          designed for the Ethereum network, MetaMask can also be configured to
          connect with various other blockchain networks, including the IDEA
          Network. This guide will walk you through the process of setting up
          MetaMask and connecting it to the IDEA Network.
        </p>
        <div className="flex justify-center sm:justify-start">
          <a href="https://metamask.io/download/" target="_blank">
            <button className="bg-custom-gradient-btn hover:bg-custom-gradient-btn1 font-poppins font-semibold text-[18px] w-24 h-9 sm:w-28 sm:h-14 rounded-2xl mt-3 sm:mx-0">
              Explore
            </button>
          </a>
        </div>
      </div>
      <div
        id="Installing and Setting up MetaMask"
        style={{ scrollMarginTop: "80px" }}
        className="my-12"
      >
        <h2 className="text-center sm:!text-left text-[18px] sm:text-[26px] md:text-[36px] lg:text-[48px]">
          Installing and Setting up MetaMask
        </h2>
        <p className="text-center sm:!text-left text-[12px] sm:text-[14px] md:text-[16px] leading-[35px] font-poppins font-normal my-4">
          MetaMask is compatible with multiple browsers, such as Chrome,
          Firefox, and Brave, and is available as a mobile app for both iOS and
          Android. While the installation process may vary slightly depending on
          your platform, the following steps will help you get started.
        </p>
      </div>
      <div id="Step 1: Download MetaMask" className="my-12">
        <h2 className="text-center sm:!text-left text-[18px] sm:text-[26px] md:text-[36px] lg:text-[48px]">
          Step 1: Download MetaMask
        </h2>
        <p className="text-center sm:!text-left text-[12px] sm:text-[14px] md:text-[16px] leading-[35px] font-poppins font-normal my-4">
          Visit the MetaMask download page.
          <br /> Select the appropriate platform (browser extension or mobile
          app) and follow the instructions to install it on your device.
        </p>
        <img
          src={meta1}
          loading="lazy"
          alt="meta1"
          className="w-full max-w-[566px] h-auto sm:h-[300px]  md:h-[350px] lg:h-[390px] mx-auto mt-12 rounded-2xl"
        />
      </div>
      <div id="Step 2: Create a New Wallet" className="my-12">
        <h2 className="text-center sm:!text-left text-[18px] sm:text-[26px] md:text-[36px] lg:text-[48px]">
          Step 2: Create a New Wallet
        </h2>
        <p className="text-center sm:!text-left text-[12px] sm:text-[14px] md:text-[16px] leading-[35px] font-poppins font-normal my-4">
          Once installed, open MetaMask and click on Get Started.
          <br /> Choose Create a Wallet. If you already have a wallet, you can
          select Import Wallet instead.
        </p>
        <img
          src={meta2}
          loading="lazy"
          alt="meta1"
          className="w-full max-w-[566px] h-auto sm:h-[300px]  md:h-[350px] lg:h-[390px] mx-auto mt-12 rounded-2xl"
        />
      </div>
      <div id="Step 3: Secure Your Wallet" className="my-12">
        <h2 className="text-center sm:!text-left text-[18px] sm:text-[26px] md:text-[36px] lg:text-[48px]">
          Step 3: Secure Your Wallet
        </h2>
        <p className="text-center sm:!text-left text-[12px] sm:text-[14px] md:text-[16px] leading-[35px] font-poppins font-normal my-4">
          You will be prompted to create a password. Choose a strong password
          for your wallet. <br />
          It’s highly recommended to secure your wallet with a Secret Recovery
          Phrase (also known as a seed phrase). This phrase acts like a master
          key to your wallet and funds.
        </p>
        <img
          src={meta3}
          loading="lazy"
          alt="meta1"
          className="w-full max-w-[566px] h-auto sm:h-[300px]  md:h-[350px] lg:h-[390px] mx-auto mt-12 rounded-2xl"
        />
      </div>
      <div
        id="Step 4: Write Down Your Secret Recovery Phrase"
        className="my-12"
      >
        <h2 className="text-center sm:!text-left text-[18px] sm:text-[26px] md:text-[36px] lg:text-[48px]">
          Step 4: Write Down Your Secret Recovery Phrase
        </h2>
        <p className="text-center sm:!text-left text-[12px] sm:text-[14px] md:text-[16px] leading-[35px] font-poppins font-normal my-4">
          After creating your wallet, MetaMask will display your Secret Recovery
          Phrase. Write it down and store it in a secure location. <br />
          Important: Never share your Secret Recovery Phrase with anyone. If you
          lose it, you may lose access to your funds.
        </p>
        <img
          src={meta4}
          loading="lazy"
          alt="meta1"
          className="w-full max-w-[566px] h-auto sm:h-[300px]  md:h-[350px] lg:h-[390px] mx-auto mt-12 rounded-2xl"
        />
      </div>
      <div id="Step 5: Confirm Your Secret Recovery Phrase" className="my-12">
        <h2 className="text-center sm:!text-left text-[18px] sm:text-[26px] md:text-[36px] lg:text-[48px]">
          Step 5: Confirm Your Secret Recovery Phrase
        </h2>
        <p className="text-center sm:!text-left text-[12px] sm:text-[14px] md:text-[16px] leading-[35px] font-poppins font-normal my-4">
          You will be asked to confirm your Secret Recovery Phrase by selecting
          the words in the correct order.
          <br />
          Click Confirm once you have entered the correct words.
        </p>
        <img
          src={meta5}
          loading="lazy"
          alt="meta1"
          className="w-full max-w-[566px] h-auto sm:h-[300px] md:h-[350px] lg:h-[370px] mx-auto mt-12 rounded-2xl"
        />
      </div>
      {/* <div className="my-12">
        <h2 className="text-center sm:!text-left text-[18px] sm:text-[26px] md:text-[36px] lg:text-[48px]">
          Step 6{" "}
        </h2>
        <p className="text-center sm:!text-left text-[12px] sm:text-[14px] md:text-[16px] leading-[35px] font-poppins font-normal my-4">
          Enter the missing words to confirm your phrase and click [Confirm].
        </p>
        <img
          src={meta6}
          loading="lazy"
          alt="meta1"
          className="w-full max-w-[566px] h-auto sm:h-[300px] md:h-[350px] lg:h-[370px] mx-auto mt-12 rounded-2xl"
        />
      </div> */}
      <div
        id="Adding the IDEA Network to Your MetaMask"
        style={{ scrollMarginTop: "100px" }}
        className="my-12"
      >
        <h2 className="text-center sm:!text-left text-[18px] sm:text-[26px] md:text-[36px] lg:text-[48px]">
          Adding the IDEA Network to Your MetaMask
        </h2>
        <p className="text-center sm:!text-left text-[12px] sm:text-[14px] md:text-[16px] leading-[35px] font-poppins font-normal my-4">
          By default, your new MetaMask wallet will be connected to the Ethereum
          network. To interact with decentralized applications (DApps) on the
          IDEA Network, you need to add it to your MetaMask. Follow these steps:
        </p>
      </div>
      <div id="Step 1: Access the Networks Menu" className="my-12">
        <h2 className="text-center sm:!text-left text-[18px] sm:text-[26px] md:text-[36px] lg:text-[48px]">
          Step 1: Access the Networks Menu
        </h2>
        <p className="text-center sm:!text-left text-[12px] sm:text-[14px] md:text-[16px] leading-[35px] font-poppins font-normal my-4">
          In the MetaMask interface, click on the network dropdown at the top
          (it usually displays "Ethereum Mainnet").
          <br />
          Select Add Network from the options.
        </p>
        <img
          src={meta7}
          loading="lazy"
          alt="meta1"
          className="w-full max-w-[466px] h-auto sm:h-[300px] md:h-[350px] lg:h-[370px] mx-auto mt-12 rounded-2xl"
        />
      </div>
      <div id="Step 2: Fill in the IDEA Network Details" className="my-12">
        <h2 className="text-center sm:!text-left text-[18px] sm:text-[26px] md:text-[36px] lg:text-[48px]">
          Step 2: Fill in the IDEA Network Details
        </h2>
        <p className="text-center sm:!text-left text-[12px] sm:text-[14px] md:text-[16px] leading-[35px] font-poppins font-normal my-4">
          Next, you have to add the network parameters. Note that these
          parameters are unique to each network.
        </p>
        <ul className="font-poppins font-semibold text-center sm:!text-left">
          <li className="font-poppins font-semibold mt-3">
            Network name: IDEA Chain Testnet
          </li>
          <li className="font-poppins font-semibold mt-3">
            New RPC URL :{" "}
            <a href="node1.ideascan.io" target="_blank">
              "node1.ideascan.io"
            </a>
          </li>
          <li className="font-poppins font-semibold mt-3">Chain ID: 2960</li>
          <li className="font-poppins font-semibold mt-3">
            Currency symbol: IDEA
          </li>
          <li className="font-poppins font-semibold mt-3">
            Block explorer URL:{" "}
            <a href="https://www.ideascan.io/" target="_blank">
              "https://www.ideascan.io/"
            </a>
          </li>
        </ul>
        <img
          src={meta8}
          loading="lazy"
          alt="meta8"
          className="w-full max-w-[466px] h-auto sm:h-[300px] md:h-[350px] lg:h-[370px] mx-auto mt-12 rounded-2xl"
        />
      </div>
      <div id="Step 3: Save the Network" className="my-12">
        <h2 className="text-center sm:!text-left text-[18px] sm:text-[26px] md:text-[36px] lg:text-[48px]">
          Step 3: Save the Network
        </h2>
        <p className="text-center sm:!text-left text-[12px] sm:text-[14px] md:text-[16px] leading-[35px] font-poppins font-normal my-4">
          After entering all the required details, click on the Save button.
        </p>
        {/* <img
          src={meta9}
          loading="lazy"
          alt="meta9"
          className="w-full max-w-[566px] h-auto sm:h-[300px] md:h-[350px] lg:h-[370px] mx-auto mt-12 rounded-2xl"
        /> */}
      </div>
      <div
        id="Step 4: Confirm Connection to the IDEA Network"
        className="my-12"
      >
        <h2 className="text-center sm:!text-left text-[18px] sm:text-[26px] md:text-[36px] lg:text-[48px]">
          Step 4: Confirm Connection to the IDEA Network
        </h2>
        <p className="text-center sm:!text-left text-[12px] sm:text-[14px] md:text-[16px] leading-[35px] font-poppins font-normal my-4">
          You should now see the IDEA Network listed in your networks. Select it
          to switch to the IDEA Network.
          <br />
          You will see the network name at the top of the MetaMask interface,
          confirming that you are now connected to the IDEA Network.
        </p>
        <img
          src={meta10}
          loading="lazy"
          alt="meta10"
          className="w-full max-w-[566px] h-auto sm:h-[300px] md:h-[350px] lg:h-[370px] mx-auto mt-12 rounded-2xl"
        />
      </div>
      <div id="Step 5: Fund Your Wallet" className="my-12">
        <h2 className="text-center sm:!text-left text-[18px] sm:text-[26px] md:text-[36px] lg:text-[48px] leading-8">
          Step 5: Fund Your Wallet
        </h2>
        <p className="text-center sm:!text-left text-[12px] sm:text-[14px] md:text-[16px] leading-[35px] font-poppins font-normal my-4">
          To use the IDEA Network, you will need to fund your MetaMask wallet
          with some IDEA tokens to cover transaction fees. You can transfer IDEA
          tokens from exchanges like Binance to your MetaMask wallet using the
          IDEA Network.
        </p>
      </div>
      <div
        id="Making Transactions on the IDEA Network"
        style={{ scrollMarginTop: "100px" }}
        className="my-12"
      >
        <h2 className="text-center sm:!text-left text-[18px] sm:text-[26px] md:text-[36px] lg:text-[48px]">
          Making Transactions on the IDEA Network
        </h2>
        <p className="text-center sm:!text-left text-[12px] sm:text-[14px] md:text-[16px] leading-[35px] font-poppins font-normal my-4">
          Once connected to the IDEA Network, you can start using DApps and
          making transactions. Here’s how to make a simple transaction using
          test funds:
        </p>
      </div>
      <div id="Step 1: Copy Your Wallet Address" className="my-12">
        <h2 className="text-center sm:!text-left text-[18px] sm:text-[26px] md:text-[36px] lg:text-[48px]">
          Step 1: Copy Your Wallet Address
        </h2>
        <p className="text-center sm:!text-left text-[12px] sm:text-[14px] md:text-[16px] leading-[35px] font-poppins font-normal my-4">
          Click on your account address at the top of the MetaMask interface to
          copy it.
        </p>
        <img
          src={meta11}
          loading="lazy"
          alt="meta11"
          className="w-full max-w-[466px] h-auto sm:h-[300px] md:h-[350px] lg:h-[370px] mx-auto mt-12 rounded-2xl"
        />
      </div>
      <div id="Step 2: Use the IDEA Network Faucet" className="my-12">
        <h2 className="text-center sm:!text-left text-[18px] sm:text-[26px] md:text-[36px] lg:text-[48px]">
          Step 2: Use the IDEA Network Faucet
        </h2>
        <p className="text-center sm:!text-left text-[12px] sm:text-[14px] md:text-[16px] leading-[35px] font-poppins font-normal my-4">
          Visit the IDEA Network Faucet and paste your wallet address into the
          appropriate field.
          <br />
          Click Request Funds to receive some test IDEA tokens.
        </p>
        <img
          src={meta12}
          loading="lazy"
          alt="meta12"
          className="w-full max-w-[466px] h-auto sm:h-[300px] md:h-[350px] lg:h-[370px] mx-auto mt-12 rounded-2xl"
        />
      </div>
      <div id="Step 3: Wait for the Funds to Arrive" className="my-12">
        <h2 className="text-center sm:!text-left text-[18px] sm:text-[26px] md:text-[36px] lg:text-[48px]">
          Step 3: Wait for the Funds to Arrive
        </h2>
        <p className=" text-center sm:!text-left text-[12px] sm:text-[14px] md:text-[16px] leading-[35px] font-poppins font-normal my-4">
          Wait a few moments for the test funds to arrive in your wallet. Note
          that these are not real funds; they are for testing purposes only.
        </p>
        <img
          src={meta13}
          loading="lazy"
          alt="meta13"
          className="w-full max-w-[466px] h-auto sm:h-[300px] md:h-[350px] lg:h-[370px] mx-auto mt-12 rounded-2xl"
        />
      </div>
      <div id="Step 4: Make a Transaction" className="my-12">
        <h2 className="text-center sm:!text-left text-[18px] sm:text-[26px] md:text-[36px] lg:text-[48px]">
          Step 4: Make a Transaction
        </h2>
        <p className="text-center sm:!text-left text-[12px] sm:text-[14px] md:text-[16px] leading-[35px] font-poppins font-normal my-4">
          To demonstrate a transaction, click on Send in MetaMask.
          <br />
          Paste the destination wallet address and enter the amount of IDEA you
          wish to send. Click Next.
        </p>
        <img
          src={meta14}
          loading="lazy"
          alt="meta14"
          className="w-full max-w-[466px] h-auto sm:h-[300px] md:h-[350px] lg:h-[370px] mx-auto mt-12 rounded-2xl"
        />
      </div>
      {/* <div className="my-12">
        <h2 className="text-center sm:!text-left text-[18px] sm:text-[26px] md:text-[36px] lg:text-[48px]">
          Step 4{" "}
        </h2>
        <p className="text-center sm:!text-left text-[12px] sm:text-[14px] md:text-[16px] leading-[35px] font-poppins font-normal my-4">
          Check the transaction details before clicking [Confirm]. In this
          example, we are sending 0.1 IDEA to this random address.
        </p>
        <img
          src={meta15}
          loading="lazy"
          alt="meta14"
          className="w-full max-w-[466px] h-auto sm:h-[300px] md:h-[350px] lg:h-[370px] mx-auto mt-12 rounded-2xl"
        />
      </div> */}
      <div id="Step 5: Confirm the Transaction" className="my-12">
        <h2 className="text-center sm:!text-left text-[18px] sm:text-[26px] md:text-[36px] lg:text-[48px]">
          Step 5: Confirm the Transaction
        </h2>
        <p className="text-center sm:!text-left text-[12px] sm:text-[14px] md:text-[16px] leading-[35px] font-poppins font-normal my-4">
          Review the transaction details and click Confirm to complete the
          transaction.
          <br />
          Wait a few seconds for the transaction to be confirmed. You can check
          your transaction history under the Activity tab in MetaMask.
        </p>
        <img
          src={meta16}
          loading="lazy"
          alt="meta14"
          className="w-full max-w-[466px] h-auto sm:h-[300px] md:h-[350px] lg:h-[370px] mx-auto mt-12 rounded-2xl"
        />
      </div>
      <div
        id="Closing Thoughts"
        // style={{ scrollMarginTop: "100px" }}
        className="my-12"
      >
        <h2 className="text-center sm:!text-left text-[18px] sm:text-[26px] md:text-[36px] lg:text-[48px]">
          Closing Thoughts
        </h2>
        <p className="text-center sm:!text-left text-[12px] sm:text-[14px] md:text-[16px] leading-[35px] font-poppins font-normal my-4">
          MetaMask has become a popular Web3 wallet for accessing the Ethereum
          ecosystem and beyond. By following these steps, you can easily
          configure MetaMask to connect to the IDEA Network, unlocking a new
          array of decentralized applications within the IDEA ecosystem. If you
          have any questions or need further assistance, feel free to reach out!
        </p>
      </div>
    </div>
    // </div>
  );
};

export default Learn;
