import React, { useContext, useEffect, useState } from "react";
import { RiSubtractFill } from "react-icons/ri";
import { IoMdAdd } from "react-icons/io";
import { FaLongArrowAltRight } from "react-icons/fa";
import { AiOutlineExclamation } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";
import { IoCopyOutline } from "react-icons/io5";
import { useConnectWallet } from "@web3-onboard/react";
import { useBlockchainDeployToken } from "../service/DeployTokenService";
import GetDeployAdress from "./GetDeployAdressModal";
import { useMetaMask } from "../ContextApi/MetaMaskProvider";
import { ring2 } from "ldrs";
import ABI from "../service/DeployTokenService/abi.json";
import ErrorModal from "./ErrorModal";

function CreateToken() {
  // =======================================================================
  // stepper 1 part start
  // ======================================================================
  // stepper 1 usestate
  const [token, setToken] = useState("");
  const [ideaToken, setIdeaToken] = useState("");
  const [isOn, setIsOn] = useState(false);
  const [contractToken, setContractToken] = useState("");
  const [supplyToken, setSupplyToken] = useState("");
  const [minToken, setMinToken] = useState("");
  const [decimal, setDecimal] = useState(18);
  const [isMinOn, setMinIsOn] = useState(false);
  const [supplyRecipientOn, setSupplyRecipientOn] = useState(false);
  const [tokenOwnerOn, setTokenOwnerOn] = useState(false);
  const [supplyRecipient, setSupplyRecipient] = useState("");
  const [tokenOwner, setTokenOwner] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [message, setMessage] = useState("");
  const [info, setInfo] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [secshowModal, setsecShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    address,
    connectWallet,
    disconnectWallet,
    copyText,
    handleGetAllDeployedTokens,
    connecting,
  } = useMetaMask();

  const { createToken, verifyContract } =
    useBlockchainDeployToken(connectWallet);
  const handleMessage = (mess) => {
    setMessage(mess);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setMessage(null);
    // setsecShowModal(true);
    setTimeout(() => {
      setsecShowModal(true);
    }, 500);
  };

  // stepper 1 function
  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleMinToggle = () => {
    setMinIsOn((prevIsOn) => !prevIsOn);
  };
  const handleToggle = () => {
    setIsOn((prevIsOn) => !prevIsOn);
  };
  const handleSupplyToggle = () => {
    setSupplyRecipientOn((prevIsOn) => !prevIsOn);
  };
  const handleTokenToggle = () => {
    setTokenOwnerOn((prevIsOn) => !prevIsOn);
  };

  const notify = (message) =>
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progressStyle: { background: "#0061f2" },
      progress: undefined,
      theme: "info",
    });
  const handleConnect = async () => {
    try {
      await connectWallet();
    } catch (error) {
      console.error("Connection error:", error);
      // Handle error as needed
    }
  };

  const handleCreateToken = async () => {
    if (!connectWallet) {
      notify("Please connect your wallet.");
      return;
    }
    if (!token || !ideaToken || !supplyToken) {
      notify("Please fill in all required fields.");
      return;
    }
    setIsLoading(true);

    const args = {
      name: token,
      symbol: ideaToken,
      initialSupply: supplyToken,
      decimals: decimal,
      mintable: isMinOn,
      maxSupply: isMinOn ? minToken : null,
      contractName: isOn ? contractToken : token,
      supplyRecipient: supplyRecipientOn ? supplyRecipient : address,
      tokenOwner: tokenOwnerOn ? tokenOwner : address,
    };

    try {
      let tokenaddress = await createToken(args);
      if (tokenaddress) {
        setShowModal(true);
        setIsLoading(false);
        setMessage("Your Deployed Token Address");
        setInfo(tokenaddress);

        let verificationResponse = await verifyContract(
          tokenaddress,
          ABI,
          args.name
        );
        console.log("Verification Response:", verificationResponse);
        resetFields();
        // } catch (error) {
        //   console.error("Contract verification failed:", error);
        // }
        // notify(`Token created successfully: ${tokenaddress}`);
      } else {
        setIsLoading(false);
        notify("Token creation failed.");
      }
      // notify("Token created successfully!");
    } catch (error) {
      notify(`Error creating token: ${error.message}`);
      setIsLoading(false);
    }
  };

  const resetFields = () => {
    setToken(""); // Reset token name
    setIdeaToken(""); // Reset symbol
    setSupplyToken(""); // Reset supply
    setDecimal(18); // Reset decimal
    setMinToken(""); // Reset max supply if mintable
    setContractToken(""); // Reset contract name
    setSupplyRecipient(""); // Reset supply recipient
    setTokenOwner(""); // Reset token owner
    setIsOn(false); // Reset contract name toggle
    setSupplyRecipientOn(false); // Reset supply recipient toggle
    setTokenOwnerOn(false); // Reset token owner toggle
  };

  const handleCopyText = () => {
    copyText();
    notify("Address Copied");
  };
  ring2.register();

  return (
    <div className="">
      <h1 className="text-lg md:text-2xl text-white w-[340px] md:w-[640px] mx-auto mt-3">
        Deploy Token
      </h1>
      <div className="mt-10 mb-20 flex justify-center ">
        <div className="bg-[#0F2434] w-[360px] md:w-[640px] h-fit p-1 md:p-2 rounded-xl  flex justify-center border-4 border-[#435562] ">
          <div>
            {/* card1 */}
            <div className="mt-[14px] md:mt-[24px]">
              <h1 className="text-[18px] md:text-[24px] text-[#ffffff] font-bold font-Poppins leading-[18px] md:leading-[26px]">
                Blockchain
              </h1>
              <div className="bg-[#FFFFFF] bg-opacity-90 h-[2px] w-[320px]  md:w-[551px] mt-[8px] md:mt-[8px]" />
              <div className="mt-[10px] md:mt-[20px]">
                <h2 className="text-[14px] md:text-[18px] text-[#ffffff] font-bold font-Poppins leading-[18px] md:leading-[26px]">
                  Connect Wallet*
                </h2>
                <p className="w-[320px] md:w-[550px] h-[20px] md:h-[26px] font-medium text-[#FFFFFF] leading-[18px] md:leading-[26px] font-Poppins text-[10px] md:text-[14px] italic opacity-50">
                  Connect your Web3 wallet.
                </p>
                {address ? (
                  <button
                    onClick={() => {
                      handleCopyText();
                    }}
                    className="w-[320px] md:w-[552px] h-[40px] md:h-[56px] bg-gradient-to-r from-[#53B6FF] to-[#1283D7] mt-2 rounded-2xl flex items-center justify-between px-4"
                  >
                    <span className="text-[10px] md:text-[18px] text-[#ffffff] font-bold font-Poppins leading-[18px] md:leading-[26px]">
                      {address}
                    </span>
                    <span className="pl-2 text-white">
                      <IoCopyOutline className="hover:cursor-pointer" />
                    </span>
                  </button>
                ) : (
                  <button
                    onClick={handleConnect}
                    className="w-[320px] md:w-[552px] h-[40px] md:h-[56px] bg-gradient-to-r from-[#53B6FF] to-[#1283D7] mt-2 rounded-2xl"
                  >
                    <span className="text-[14px] md:text-[18px] text-[#ffffff] font-bold font-Poppins leading-[18px] md:leading-[26px]">
                      Connect Wallet
                    </span>
                  </button>
                )}
                <p className="italic text-[10px] md:text-[14px] text-[#ffffff] mt-3">
                  Connect with IDEA 3.0
                </p>
              </div>

              {/* <div className="mt-[10px] md:mt-[20px]"> */}
              {/* dropdown */}

              {/* </div> */}
            </div>
            {/* card2 */}
            {/* part2  */}
            <div className="mt-[25px]">
              <h1 className="text-[18px] md:text-[24px] text-[#ffffff] font-bold font-Poppins leading-[18px] md:leading-[26px]">
                General
              </h1>
              <div className="bg-[#FFFFFF] bg-opacity-90 h-[2px] w-[320px]  md:w-[551px] mt-[4px] md:mt-[8px]" />
              {/* part 1 */}
              <div className="mt-[30px]">
                <div className="flex justify-between w-[320px]  md:w-[551px]">
                  <div className="text-[14px] md:text-[18px] text-[#ffffff] font-bold font-Poppins leading-[18px] md:leading-[26px]">
                    Token Name*
                  </div>
                  <a
                    href="#"
                    className=" text-[#1283D7] leading-[18px] md:leading-[26px] font-Poppins font-semibold text-[10px] md:text-[14px] hover:underline "
                  >
                    {/* Check Docs */}
                  </a>
                </div>
                <p className="w-[320px] md:w-[550px] h-[18px] md:h-[26px] font-medium text-[#FFFFFF] leading-[18px] md:leading-[26px] font-Poppins text-[10px] md:text-[14px] italic opacity-50">
                  Choose a great name for your token.
                </p>
              </div>
              {/* input tag1 */}
              <div className="mt-2">
                <input
                  className="w-[320px] md:w-[552px] h-[40px] md:h-[56px] font-medium px-3  bg-[#31414E80] text-[12px] rounded-2xl bg-opacity-50 outline-none md:text-[18px] p-1 md:p-2 text-[#ffffff] "
                  type="text"
                  id="tokenInput"
                  placeholder="e.g  My Token"
                  value={token}
                  onChange={(e) => setToken(e.target.value)}
                />

                <div className="bg-[#31414E] w-[320px] md:w-[552px] h-[40px] md:h-[56px] rounded-2xl mt-2 flex items-center">
                  <span className="leading-[18px] md:leading-[26px] font-Poppins text-[10px] md:text-[14px] p-2  text-[#FFFFFF80] flex justify-center items-center italic">
                    <AiOutlineExclamation className="ml-2 mr-2 md:mr-4 w-3 md:w-4 h-3 md:h-4 rounded-full text-[#ffffff] bg-[#8F99A0]" />
                    Max 50 characters. (Not editable later)
                  </span>
                </div>
              </div>
              {/* part2 */}
              <div className="mt-[30px]">
                <div className="flex justify-between w-[320px]  md:w-[551px]">
                  <div className="text-[14px] md:text-[18px] text-[#ffffff] font-bold font-Poppins leading-[18px] md:leading-[26px]">
                    Token Symbol*
                  </div>
                  <a
                    href="#"
                    className=" text-[#1283D7] leading-[18px] md:leading-[26px] font-Poppins font-semibold text-[10px] md:text-[14px] hover:underline "
                  >
                    {/* Check Docs */}
                  </a>
                </div>
                <p className="w-[320px] md:w-[550px] my-2 h-[18px] md:h-[26px] font-medium text-[#FFFFFF] leading-[18px] md:leading-[26px] font-Poppins text-[10px] md:text-[14px] italic opacity-50">
                  Choose a great symbol for your token.
                </p>
              </div>
              {/* input tag 2 */}
              <div className="mt-2">
                <input
                  className="w-[320px] md:w-[552px] h-[40px] md:h-[56px] outline-none px-3 font-medium bg-[#31414E80] text-[12px] md:text-[18px]  rounded-2xl bg-opacity-50 p-1 md:p-2 text-[#ffffff] "
                  type="text"
                  id="tokenInput"
                  placeholder="e.g IDEA"
                  value={ideaToken}
                  onChange={(e) => setIdeaToken(e.target.value)}
                />

                <div className="bg-[#31414E] w-[320px] md:w-[552px] h-[40px] md:h-[56px] rounded-2xl mt-2 flex items-center">
                  <span className="leading-[18px] md:leading-[26px] italic font-Poppins text-[10px] md:text-[14px] p-2  text-[#FFFFFF80] flex justify-center items-center">
                    <AiOutlineExclamation className="ml-2 mr-2 md:mr-4 w-3 md:w-4 h-3 md:h-4 text-[#ffffff] rounded-full bg-[#8F99A0]" />{" "}
                    Max 20 characters. (Not editable later)
                  </span>
                </div>
              </div>
              {/* input tag ended */}
              {/* contract name part */}
              <div className="mt-[30px]">
                <div className="flex justify-between w-[320px]  md:w-[551px]">
                  <div className="text-[14px] md:text-[18px] text-[#ffffff] font-bold font-Poppins leading-[18px] md:leading-[26px]">
                    Contract Name*
                  </div>
                  <a
                    href="#"
                    className=" text-[#1283D7] leading-[18px] md:leading-[26px] font-Poppins font-semibold text-[10px] md:text-[14px] hover:underline "
                  >
                    {/* Check Docs */}
                  </a>
                </div>
                <p className="w-[320px] md:w-[550px] h-[18px] my-2 md:h-[26px] font-medium text-[#FFFFFF] leading-[18px] md:leading-[26px] font-Poppins text-[10px] md:text-[14px] italic opacity-50">
                  Smart contract name that is being derived from your token name
                  and visible after verification. If you are not sure, we
                  recommend keeping the default value.
                </p>
                <div className="flex mt-[45px] md:mt-[45px] ">
                  <div className="font-Poppins leading-[18px] md:leading-[26px] text-[13px] md:text-[18px] mt-3 mb-2 font-semibold text-[#ffffff] ">
                    Use Default
                  </div>

                  <div className="ml-3 mt-3 mb-2">
                    <div className="leading-[18px] md:leading-[26px]  flex justify-between ">
                      <label className="inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          value=""
                          className="sr-only peer"
                          onChange={handleToggle}
                          checked={isOn}
                        />
                        <div className="relative w-7 h-4 md:w-11 md:h-6 bg-[#5570F1] peer-checked:bg-blue-600 bg-opacity-15 rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#BBC5CB] after:rounded-full after:h-3 after:w-3 md:after:h-5 md:after:w-5 after:transition-all "></div>
                        <span className="font-Poppins ml-2 leading-[18px] md:leading-[26px] text-[13px] md:text-[18px] font-semibold text-[#ffffff]">
                          Use Custom
                        </span>
                      </label>
                                
                    </div>
                  </div>
                </div>
                {/* toogle data */}

                {isOn ? (
                  <div>
                    <input
                      className="w-[320px] md:w-[552px] h-[40px] text-[12px] md:text-[18px] px-2 md:h-[56px] bg-[#31414E] rounded-2xl bg-opacity-50 outline-none mt-2 md:p-2 text-[#ffffff] "
                      type="text"
                      id="tokenInput"
                      placeholder=""
                      value={contractToken}
                      onChange={(e) => setContractToken(e.target.value)}
                    />
                    <div className="bg-[#31414E] w-[320px] md:w-[552px] h-[40px] md:h-[56px]  rounded-2xl mt-2 p-2 md:p-4 flex items-center">
                      <span className="leading-[16px] md:leading-[26px] font-Poppins rounded-2xl text-[10px] md:text-[14px] p-1 md:p-2  text-[#FFFFFF80] flex justify-center items-center">
                        <AiOutlineExclamation className="ml-2 mr-2 md:mr-4 w-4 md:w-4 h-3 text-[#ffffff] md:h-4 rounded-full bg-[#8F99A0]" />{" "}
                        Enter smart contract name for your token. Max 50
                        characters. (Not editable later)
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="bg-[#31414E] w-[320px] md:w-[552px] bg-opacity-50 h-[40px] md:h-[56px] rounded-2xl mt-2 p-2 flex items-center">
                    <input
                      type="text"
                      id="tokenInput"
                      placeholder=""
                      className="leading-[18px] md:leading-[26px] font-Poppins text-[10px] rounded-2xl md:text-[14px] p-2 bg-[#31414E] bg-opacity-50 outline-none text-[#FFFFFF80] w-[320px] md:w-[552px]"
                    />
                  </div>
                )}

                {/*  */}
              </div>

              {/* supply part */}
              <div className="mt-[30px]">
                <div className="flex justify-between w-[320px]  md:w-[551px]">
                  <div className="text-[14px] md:text-[18px] text-[#ffffff] font-bold font-Poppins leading-[18px] md:leading-[26px]">
                    Initial Supply*
                  </div>
                  <a
                    href="#"
                    className=" text-[#1283D7] leading-[18px] md:leading-[26px] font-Poppins font-semibold text-[10px] md:text-[14px] hover:underline "
                  >
                    {/* Check Docs */}
                  </a>
                </div>
                <p className="w-[320px] md:w-[550px] h-[18px] md:h-[26px] font-medium text-[#FFFFFF] leading-[18px] md:leading-[26px] font-Poppins text-[10px] md:text-[14px] italic opacity-50 my-3">
                  Choose how many tokens you want to create.
                </p>
                {/* input supply start */}
                <div className="mt-1">
                  <input
                    className="w-[320px] md:w-[552px] h-[40px] md:h-[56px] bg-[#31414E] rounded-2xl outline-none px-3 text-[10px] md:text-[14px] bg-opacity-50 p-2 text-[#ffffff] "
                    type="text"
                    id="tokenInput"
                    placeholder="10000"
                    value={supplyToken}
                    onChange={(e) => setSupplyToken(e.target.value)}
                  />
                  <div className="bg-[#31414E] w-[320px] md:w-[552px] h-[40px] md:h-[56px]  rounded-2xl mt-2 p-2 md:p-4 flex items-center">
                    <span className="leading-[18px] md:leading-[26px] font-Poppins text-[10px] md:text-[14px]  text-[#FFFFFF80] rounded-2xl flex justify-center items-center">
                      <AiOutlineExclamation className="ml-2 mr-2 md:mr-4 w-5 md:w-5 h-3 md:h-4 rounded-full bg-[#8F99A0]" />{" "}
                      Enter smart contract name for your token. Max 50
                      characters. (Not editable later)
                    </span>
                  </div>
                </div>
                {/* input supply end */}
              </div>

              {/* mintable part */}

              <div>
                <div className="mt-[30px]">
                  <div className="flex justify-between w-[320px]  md:w-[551px]">
                    {/* toogle mintable*/}
                    <div>
                      <div className="leading-[26px] flex justify-between ">
                        <label className="inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            value=""
                            className="sr-only peer"
                            onChange={handleMinToggle}
                            checked={isMinOn}
                          />
                          <div className="relative w-7 h-4 md:w-11 md:h-6 bg-[#5570F1] peer-checked:bg-blue-600 bg-opacity-15 rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#BBC5CB] after:rounded-full after:h-3 after:w-3 md:after:h-5 md:after:w-5 after:transition-all "></div>
                          <span className="ml-2 font-semibold text-[13px] md:text-[18px] text-[#ffffff] dark:text-gray-300">
                            Mintable
                          </span>
                        </label>
                                  
                      </div>
                    </div>
                    <a
                      href="#"
                      className=" text-[#1283D7] leading-[18px] md:leading-[26px] font-Poppins font-semibold text-[10px] md:text-[14px] hover:underline "
                    >
                      {/* Check Docs */}
                    </a>
                  </div>
                  <p className="w-[320px] md:w-[550px] h-[40px] md:h-[52px] font-medium mt-2 text-[#FFFFFF] leading-[18px] md:leading-[26px] font-Poppins text-[10px] md:text-[14px] italic opacity-50">
                    If the Mintable feature is enabled, the owner will be able
                    to mint new tokens until the total supply reaches the
                    maximum supply.
                  </p>
                </div>
                {/*  */}
                {isMinOn ? (
                  <div className="">
                    <input
                      className="w-[320px] md:w-[552px] h-[40px] md:h-[56px] bg-[#31414E80] rounded-2xl text-[10px] outline-none md:text-[14px] bg-opacity-50 px-3 text-[#ffffff] mt-4 "
                      type="text"
                      id="tokenInput"
                      placeholder="Max Supply"
                      value={minToken}
                      onChange={(e) => setMinToken(e.target.value)}
                    />
                    <div className="bg-[#31414E] w-[320px] md:w-[552px] h-[40px] md:h-[56px] md:text-[14px] rounded-2xl mt-2  flex items-center">
                      <span className="w-[320px] md:w-[552px] h-[40px] md:h-[56px] bg-[#31414E80] rounded-2xl bg-opacity-50 md:p-2 text-[10px] md:text-[14px] text-[#ffffff] text-opacity-50 flex items-center">
                        <AiOutlineExclamation className="ml-2 mr-2 md:mr-4 w-3 md:w-4 h-3 md:h-4 rounded-full text-[#ffffff] bg-[#8F99A0]" />{" "}
                        Initial supply ≤ Max supply ≤ 9 999 999 999 999 999.9
                      </span>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              {/* mintable ended */}
              {/* disclaimer part */}

              <div>
                <div className="mt-[30px]">
                  <div className="flex justify-between w-[320px]  md:w-[551px]">
                    <div className="text-[14px] md:text-[18px] text-[#ffffff] font-bold font-Poppins leading-[18px] md:leading-[26px]">
                      Decimals*
                    </div>
                    <a
                      href="#"
                      className=" text-[#1283D7] leading-[18px] md:leading-[26px] font-Poppins font-semibold text-[10px] md:text-[14px] hover:underline "
                    >
                      {/* Check Docs */}
                    </a>
                  </div>
                  <p className="w-[320px] md:w-[550px] h-[40px] md:h-[52px] mt-2 font-medium text-[#FFFFFF] leading-[18px] md:leading-[26px] font-Poppins text-[10px] md:text-[14px] italic opacity-50 my-3">
                    Choose how many decimal places your tokens can be divided
                    to. If you are not sure, we recommend leaving it at 18.
                  </p>
                </div>
                <div>
                  <div className="bg-[#31414E80] w-[320px] md:w-[552px] h-[40px] md:h-[56px] rounded-2xl mt-1 items-center">
                    <div className="flex items-center justify-between p-1">
                      <div className=" w-[55px] md:w-[63px] h-[32px] md:h-[48px] bg-[#31414E] rounded-2xl flex items-center justify-center ">
                        <RiSubtractFill
                          onClick={() =>
                            setDecimal((decimal) =>
                              decimal > 1 ? decimal - 1 : decimal
                            )
                          }
                          className="text-[#ffffff] w-4 h-4 font-bold"
                        />
                      </div>
                      <div className="text-[#ffffff] text-[14px] md:text-[18px] font-bold">
                        {decimal}
                      </div>
                      <div className=" w-[55px] md:w-[63px] h-[32px] md:h-[48px] bg-[#31414E] rounded-2xl flex items-center justify-center ">
                        <IoMdAdd
                          onClick={() =>
                            setDecimal((decimal) =>
                              decimal < 18 ? decimal + 1 : decimal
                            )
                          }
                          className="text-[#ffffff] w-4 h-4 font-bold"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="bg-[#31414E] w-[320px] md:w-[552px] h-[40px] md:h-[56px]  rounded-2xl mt-2 p-2 md:p-4 flex items-center">
                    <span className="w-[320px] md:w-[552px] h-[40px] md:h-[50px] bg-[#31414E80] rounded-sm text-[10px] md:text-[14px] text-[#FFFFFF80]  bg-opacity-50 p-1 md:p-2  flex  items-center ">
                      <AiOutlineExclamation className="ml-2 mr-2 md:mr-4 w-3 md:w-4 h-3 md:h-4 text-[#ffffff] rounded-full bg-[#8F99A0] " />{" "}
                      1 ≤ Decimals ≤ 18. (Not editable later)
                    </span>
                  </div>
                </div>
              </div>
              {/* disclaimer part ended */}
            </div>
            {/* part2 ended */}
            {/* part3 start  */}
            <div>
              {/* top */}
              <div className="mt-[30px]">
                <h1 className="text-[18px] md:text-[24px] text-[#ffffff] font-bold font-Poppins leading-[18px] md:leading-[26px]">
                  Supply Recipient & Token Owner
                </h1>
                <div className="bg-[#FFFFFF] bg-opacity-90 h-[2px] w-[320px] md:w-[551px] mt-3" />
              </div>

              {/* supply part */}

              <div>
                <div className="mt-[20px]">
                  <div className="flex justify-between w-[320px]  md:w-[551px]">
                    {/* toogle */}
                    <div>
                      <div className="leading-[26px] flex justify-between ">
                        <label className="inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            value=""
                            className="sr-only peer"
                            onChange={handleSupplyToggle}
                            checked={supplyRecipientOn}
                          />
                          <div className="relative w-7 h-4 md:w-11 md:h-6 bg-[#5570F1] peer-checked:bg-blue-600 bg-opacity-15 rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#BBC5CB] after:rounded-full after:w-3 after:h-3 md:after:h-5 md:after:w-5 after:transition-all "></div>
                          <span className="ms-3 font-semibold text-[13px] md:text-[18px] text-[#ffffff] dark:text-gray-300">
                            Different supply recipient
                          </span>
                        </label>
                                  
                      </div>
                    </div>
                    <a
                      href="#"
                      className=" text-[#1283D7] leading-[18px] md:leading-[26px] font-Poppins font-semibold text-[10px] md:text-[14px] hover:underline "
                    >
                      {/* Check Docs */}
                    </a>
                  </div>
                  <p className="w-[320px] md:w-[550px] h-[40px] md:h-[52px] font-medium text-[#FFFFFF] leading-[18px] md:leading-[26px] font-Poppins text-[10px] md:text-[14px] my-2 italic opacity-50">
                    Instead of your wallet, enter a different address to receive
                    the tokens after they are created.
                  </p>
                </div>
                {/*  */}
                {supplyRecipientOn ? (
                  <div>
                    <input
                      className="w-[320px] md:w-[552px] h-[40px] md:h-[56px] bg-[#31414E80] rounded-2xl bg-opacity-50 outline-none text-[12px] md:text-[14px] px-3 text-[#ffffff] "
                      type="text"
                      id="supplyRecipient"
                      placeholder=""
                      value={supplyRecipient}
                      onChange={(e) => setSupplyRecipient(e.target.value)}
                    />
                    <div className="bg-[#31414E] w-[320px] md:w-[552px] h-[40px] md:h-[56px] rounded-2xl mt-2 p-2 flex items-center">
                      <span className="w-[320px] md:w-[552px] h-[40px] md:h-[56px] bg-[#31414E80] rounded-2xl bg-opacity-50 p-1 md:p-2 text-[10px] md:text-[14px] text-[#FFFFFF80] flex justify-start  items-center ">
                        <AiOutlineExclamation className="ml-2 mr-2 md:mr-4 w-3 md:w-4 h-3 text-[#ffffff] md:h-4 rounded-full bg-[#8F99A0]" />{" "}
                        Enter valid wallet address.
                      </span>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              {/* supply ended ended */}
              {/* token owner part */}

              <div>
                <div className="mt-[20px]">
                  <div className="flex justify-between w-[320px]  md:w-[551px]">
                    {/* toogle */}
                    <div>
                      <div className="leading-[26px] flex justify-between ">
                        <label className="inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            value=""
                            className="sr-only peer"
                            onChange={handleTokenToggle}
                            checked={tokenOwnerOn}
                          />
                          <div className="relative w-7 h-4 md:w-11 md:h-6 bg-[#5570F1] peer-checked:bg-blue-600 bg-opacity-15 rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#BBC5CB] after:rounded-full after:h-3 after:w-3 md:after:h-5 md:after:w-5 after:transition-all "></div>
                          <span className="ms-3 font-semibold text-[14px] md:text-[18px] text-[#ffffff] dark:text-gray-300">
                            Different token owner
                          </span>
                        </label>
                                  
                      </div>
                    </div>
                    <a
                      href="#"
                      className=" text-[#1283D7] leading-[18px] md:leading-[26px] font-Poppins font-semibold text-[10px] md:text-[14px] hover:underline "
                    >
                      {/* Check Docs */}
                    </a>
                  </div>
                  <p className="w-[320px] md:w-[550px] h-[40px] md:h-[52px] font-medium my-2 text-[#FFFFFF] leading-[18px] md:leading-[26px] font-Poppins text-[10px] md:text-[14px] italic opacity-50">
                    Instead of your wallet, enter a different address that will
                    have ownership of the token after it is created.
                  </p>
                </div>
                {/*  */}
                {tokenOwnerOn ? (
                  <div>
                    <input
                      className="w-[320px] md:w-[552px] h-[40px] md:h-[56px] bg-[#31414E80] outline-none text-[12px] md:text-[14px] rounded-2xl bg-opacity-50 px-3 text-[#ffffff] "
                      type="text"
                      id="tokenOwner"
                      placeholder=""
                      value={tokenOwner}
                      onChange={(e) => setTokenOwner(e.target.value)}
                    />
                    <div className="bg-[#31414E] w-[320px] md:w-[552px] h-[40px] md:h-[56px]  rounded-2xl mt-2 p-2 flex items-center">
                      <span className="w-[320px] md:w-[552px] h-[40px] md:h-[56px] bg-[#31414E80] rounded-sm bg-opacity-50 text-[10px] md:text-[14px] text-[#FFFFFF80] flex items-center ">
                        <AiOutlineExclamation className="ml-2 mr-2 md:mr-2 w-3 md:w-5 h-3 md:h-5 rounded-full bg-[#8F99A0]" />{" "}
                        Enter valid wallet address.
                      </span>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              {/* token owner ended ended */}
              <div className="mt-[20px] mb-[10px]">
                {!isLoading ? (
                  <button
                    onClick={handleCreateToken}
                    className="w-[320px] md:w-[552px] h-[40px] md:h-[56px] bg-gradient-to-r from-[#53B6FF] to-[#1283D7] rounded-2xl flex items-center justify-center"
                  >
                    <span className="text-[14px] md:text-[18px] text-[#ffffff] font-bold font-Poppins leading-[18px] md:leading-[26px]">
                      Deploy & Verify Token
                    </span>
                    <span className="text-[14px] md:text-[18px] text-[#ffffff] font-bold font-Poppins pl-2 leading-[18px] md:leading-[26px]">
                      <FaLongArrowAltRight />
                    </span>
                  </button>
                ) : (
                  <button className="w-[320px] md:w-[552px] h-[40px] md:h-[56px] bg-gradient-to-r from-[#53B6FF] to-[#1283D7] rounded-2xl flex items-center justify-center">
                    <div className="flex justify-center items-center mt-2">
                      <span>
                        <l-ring-2
                          size="40"
                          stroke="5"
                          stroke-length="0.25"
                          bg-opacity="0.1"
                          speed="0.8"
                          color="white"
                        ></l-ring-2>
                      </span>
                    </div>
                  </button>
                )}
                <ToastContainer toastStyle={{ backgroundColor: "black" }} />
              </div>
            </div>
            {/* part3 ended*/}
          </div>
        </div>
      </div>
      {showModal && (
        <GetDeployAdress
          show={showModal}
          title="Deployed Token Address"
          info={info}
          handleCloseModal={handleCloseModal}
        />
      )}
      {secshowModal && (
        <ErrorModal
          show={secshowModal}
          title="Congragulations!"
          message="Your token will be verified in 5 to 8 minutes."
          handleCloseModal={() => setsecShowModal(false)}
        />
      )}
    </div>
  );
}

export default CreateToken;
